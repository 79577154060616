import React, {Reducer, useEffect, useReducer, useState} from "react";
import {ProductSearchView} from "../../reducers/product/types";
import {useI18n, WithPlaceholders} from "../../i18n/I18nSupport";
import {useCartPopUp} from "../../reducers/ui/cartPopUp";
import {useProductIdState} from "../../reducers/product/setProduct";
import {Link} from "react-router-dom";
import {createImgProxyUrl} from "./createImageProxy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {definition as faHeart} from "@fortawesome/free-solid-svg-icons/faHeart";
import {definition as faHeartReg} from "@fortawesome/free-regular-svg-icons/faHeart";
import {useProfileUpdateWishList} from "../../reducers/profile/updateWishListItems";
import {isSuccess} from "../../reducers/networkStateReducer";
import {keycloak} from "../../auth/AuthProvider";
import {useSchema} from "../../reducers/schema";
import {useDefaultCurrencyCode} from "../../reducers/ui/defaultCurrencyCode";
import {toast} from "react-toastify";
import {toastCustomOptions} from "./commons";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {BuyNowButton} from "../Main/Body/Product/ProductInfo/BuyNowButton";
import {
    initProductState,
    productStateReducer,
    QuantityAction,
    QuantityState
} from "../Main/Body/Product/ProductInfo/util";
import {it} from "jest-circus";


export function OutOfStockLabelling() {

    const {tx} = useI18n("product.info.stockLabel")

    return <a className="btn btn-out-of-stock"><span style={{opacity: 1.0}}>{tx`outOfStock`}</span></a>

}

type AddToCartButtonProps = {
    baseId: string,
    setCartPopupState: (open: boolean) => void,
    setProductId: (id: string, merchantId: string) => void
}

export function AddToCartButton({baseId, setCartPopupState, setProductId}: AddToCartButtonProps) {

    const {ts} = useI18n("product.info.addToCartButtonLabel")

    return <a href="javascript:void(0)"
              id={"add-to-cart-button"}
              className="buy-ticket-card"
              onClick={(e) => {
                  setCartPopupState(true)
                  setProductId(baseId, "")
              }}

    >
        Buy Ticket
        <i className="fa fa-angle-right ml-1"></i>
    </a>

}


export function BlankLabelHolder() {

    return <div className="d-inline-block ml-1">
        <div className="row">
            <h6 className="mt-2 mb-0 font-weight-bold text-white" style={{
                position: "relative",
                bottom: 0,
                alignItems: "start",
                fontSize: "12px",
            }}>&nbsp;</h6>
        </div>
        <div className="row">
            <div className="d-flex flex-row" style={{alignItems: "space-between", position: "absolute"}}>
                <h6 className=" mt-2 mb-0 font-weight-bold">&nbsp;</h6>
            </div>
        </div>
    </div>

}

export function ProductDescription() {
    let {tx} = useI18n('product.card');
    const [schema] = useSchema();

    if (isSuccess(schema)) {
        if (schema.data.generalSettings?.hideProductPrice == true) {
            return <></>
        }
    }

    return <p className="price-text serandisco-price"> {tx`price`}</p>
}


export function ProductDescriptionWithSpecialPrice() {
    let {tx} = useI18n('product.card');
    const [schema] = useSchema();

    if (isSuccess(schema)) {
        if (schema.data.generalSettings?.hideProductPrice == true) {
            return <></>
        }
    }

    return <p><s className="price-text">{tx`price`}</s>  {tx`specialPrice`}</p>
}

interface SearchResultCardProps {
    item: ProductSearchView
    isAvailable?: boolean
    isInStock?: boolean
}

interface ResponsiveCardProps {
    item: ProductSearchView,
    showCart: boolean,
    isAvailable?: boolean,
    isInStock?: boolean

}

function ResponsiveCard({item, showCart, isAvailable, isInStock}: ResponsiveCardProps) {
    let [state, dispatch] = useReducer<Reducer<QuantityState, QuantityAction>>(productStateReducer, initProductState);
    let [, setCartPopupState] = useCartPopUp()
    let [, setProductId] = useProductIdState()
    const [updatedProfile, doAddToWishList, reset] = useProfileUpdateWishList()
    const [currency] = useDefaultCurrencyCode();
    const [schema] = useSchema();
    const [currCurrency, setCurrCurrency] = useState<string>();

    let {tx, ts} = useI18n('product.card');

    function checkAvailability(node: ProductSearchView): boolean {
        if (isSuccess(updatedProfile)) {
            return updatedProfile.data.wishList
                ?.map((v) => v.baseId)
                .includes(item.baseId ?? "") ?? false
        }
        return isAvailable ?? false
    }

    console.log("ItemNode",item)

    useEffect(() => {
        if (isSuccess(updatedProfile)) {
            return reset
        }
    }, [updatedProfile])
    console.log("prodItem",item)


    useEffect(() => {
        if (!!currency && !currCurrency) {
            setCurrCurrency(currency)
        }
    }, [currency])

    function MouseOver(event: any) {
        event.target.classList.add('fade');
        setTimeout(() => {
            if (item.iconUrl && item.iconUrl[1]) {
                event.target.src = createImgProxyUrl(item.iconUrl[1], 600, 615, 'fill');
                event.target.classList.remove('fade');
            }
        }, 300);
    }

    function MouseOut(event: any) {
        event.target.classList.add('fade');
        setTimeout(() => {
            if (item.iconUrl) {
                event.target.src = createImgProxyUrl(item.iconUrl[0], 600, 615, 'fill');
                event.target.classList.remove('fade');
            }
        }, 300);
    }


    const productLink = !!item.productSlug ? `/products/${item.productSlug}` : `/product/${item.id}`

    return <Link to={productLink} style={{textDecoration:"none"}}>
    <div className="serandesco-product-thumb-wrapper">
        <div className="product-img-box">
            <LazyLoadImage
                id={"product-card-image"}
                className="w-100 product-image"
                style={{borderRadius: "10px"}}
                src={createImgProxyUrl(((item.default ? (item?.imageReference ?? item?.iconUrl?.[0]) : item?.iconUrl?.[0])), 600, 615, 'fill')}
                onMouseOver={MouseOver}
                onMouseLeave={MouseOut}
            />

        </div>
        <div className="product-thumb-content">
            <Link style={{textDecoration: "none", fontSize: "13px", color: "black"}}
                  to={productLink}
                  className="products-section__product-name"
            >
                <h5 id={"serandesco-product-card-name"}
                    className={"product-card-name"}
                    data-toggle="tooltip"
                    title={ts`name`}
                    style={{
                        display: "block",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontSize:"14px",
                        textAlign:"center"
                    }}
                > {tx`name`}</h5>
            </Link>
            {!!currCurrency
            && !!PriceConversion(currCurrency, item.specialPrice)
            && !!PriceConversion(currCurrency, item.price)
            && PriceConversion(currCurrency, item.specialPrice) < PriceConversion(currCurrency, item.price) && item.enableSpecialPrice ?
                <ProductDescriptionWithSpecialPrice/>
                :
                <ProductDescription/>
            }

            {isSuccess(schema) && !schema.data.generalSettings?.useSiteAsCatalog &&
                (isInStock ?
                        // <AddToCartButton baseId={item.baseId ?? ""} setCartPopupState={setCartPopupState}
                        //                  setProductId={setProductId}/>
                        <button className="buy-ticket-card">{tx`buyTicket`}</button>
                        :
                        <OutOfStockLabelling/>
                )
            }
        </div>
    </div>
    </Link>
}

export function PriceConversion(currencyCode?: string, price?: { [key: string]: number }): number {

    let code = currencyCode?.split("|")[0] ?? "USD|$"
    return !!price && !!price[code] ? price[code] : 0
}

export function SerandescoProductCard({item, isAvailable, isInStock}: SearchResultCardProps) {

    let [, setCartPopupState] = useCartPopUp()
    let [, setProductId] = useProductIdState()
    let [showCart, setShowCart] = useState<boolean>(false)
    const [currency] = useDefaultCurrencyCode();
    const [currCurrency, setCurrCurrency] = useState<string>();

    useEffect(() => {
        if (!!currency && !currCurrency) {
            setCurrCurrency(currency)
        }
    }, [currency])

    return <WithPlaceholders value={{
        product: item,
        fraction: ((PriceConversion(currCurrency, item.price) ?? 0) - (PriceConversion(currCurrency, item.specialPrice) ?? 0)) / (PriceConversion(currCurrency, item.price) ?? 0),
        percentage: Math.round((((PriceConversion(currCurrency, item.price) ?? 0) - (PriceConversion(currCurrency, item.specialPrice) ?? 0)) / (PriceConversion(currCurrency, item.price) ?? 0)) * 100),
    }}>
        <div className="col-lg-3 col-sm-6 col-6 d-none d-xl-block d-lg-block d-md-block px-1 mb-2"
             onMouseEnter={(e) => {
                 setShowCart(true)
             }} onMouseLeave={() => {
            setShowCart(false)
        }}>
            <ResponsiveCard item={item} showCart={showCart} isAvailable={isAvailable} isInStock={isInStock}
            />
        </div>
        <div className="col-lg-3 col-sm-6 col-6 d-block d-xl-none d-lg-none d-md-none" onMouseEnter={(e) => {
            setShowCart(true)
        }} onMouseLeave={() => {
            setShowCart(false)
        }}>
            <ResponsiveCard item={item} showCart={true} isAvailable={isAvailable} isInStock={isInStock}/>
        </div>
    </WithPlaceholders>
}


